import { Box, styled } from '@qcx/ui';
import { startTransition, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigation } from 'react-router';

const PageLoadingIndicator = () => {
  const navigation = useNavigation();
  const active = useMemo(() => navigation.state !== 'idle', [navigation.state]);

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    startTransition(() => {
      if (!ref.current) {
        return;
      }

      if (active) {
        setAnimationComplete(false);
      }

      if (typeof ref.current.getAnimations === 'function') {
        Promise.allSettled(
          ref.current.getAnimations().map(({ finished }) => finished)
        ).then(() => !active && setAnimationComplete(true));
      } else {
        setTimeout(() => !active && setAnimationComplete(true), 300);
      }
    });
  }, [active]);

  return (
    <Box
      css={{
        minMaxW: '$screen',
        pointerEvents: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '$50',
        h: '$1'
      }}
      aria-hidden={!active}
      aria-valuetext={active ? 'Carregando' : undefined}
      role="progressbar"
    >
      <StyledProgressBar
        ref={ref}
        complete={animationComplete}
        state={navigation.state}
      />
    </Box>
  );
};

const StyledProgressBar = styled('div', {
  h: '$full',
  bgColor: '$primary',
  transition: 'all 0.3s ease-in-out',
  variants: {
    state: {
      idle: {},
      loading: {
        w: '$10of12'
      },
      submitting: {
        w: '$4of12'
      }
    },
    complete: {
      true: {},
      false: {}
    }
  },
  compoundVariants: [
    {
      state: 'idle',
      complete: true,
      css: {
        w: 0,
        opacity: 0,
        transition: 'none'
      }
    },
    {
      state: 'idle',
      complete: false,
      css: {
        w: '$full'
      }
    }
  ]
});

export { PageLoadingIndicator };
