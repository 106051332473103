import archivoCSS from '@fontsource-variable/archivo/wght.css?inline';
import interCSS from '@fontsource-variable/inter/wght.css?inline';
import { ASSETS_URL } from './lib/constants/url.lib';
import { AuthModalStoreProvider } from '@kratos/auth';
import { EditorialsStoreProvider } from './pages/editorials/editorials.store';
import { ErrorScreen } from './components/ErrorScreen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  isRouteErrorResponse,
  useRouteError
} from 'react-router';
import { PageLoadingIndicator } from './components/PageLoadingIndicator';
import { Script } from './components/Script';
import {
  ShellStoreProvider,
  useShellStore
} from './components/shell/shell.store';
import { Suspense } from 'react';
import { UIAppProvider, UIHeadElements, UIProvider, useMounted } from '@qcx/ui';
import { dark, light } from './styles/theme';
import { getPrivateEnvVars, getPublicEnvVars } from './lib/env.lib';
import type { Route } from './+types/root';

const theme = { light, dark };

const env = getPublicEnvVars();

export const links: Route.LinksFunction = () => [
  {
    rel: 'icon',
    href: `${ASSETS_URL}/images/qconcursos/favicon.ico`,
    type: 'image/x-icon'
  },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap'
  }
];

export const shouldRevalidate: ShouldRevalidateFunction = () => false;

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <ShellStoreProvider>
      <UIAppProvider
        imageConfig={{
          loaderPreset: 'cloudflare-image-resizing',
          loaderOptions: {
            url: 'https://image-resize.qconcursos.com'
          }
        }}
      >
        <html className="light" lang="pt-br">
          <head>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1,viewport-fit=cover"
            />
            <Meta />
            <style
              dangerouslySetInnerHTML={{
                __html: `${interCSS}${archivoCSS}`
              }}
            />
            <Links />
            <UIHeadElements />
          </head>
          <body>
            <UIProvider theme={theme}>
              <Suspense>
                <PageLoadingIndicator />
              </Suspense>
              <AuthModalStoreProvider>
                <EditorialsStoreProvider>{children}</EditorialsStoreProvider>
              </AuthModalStoreProvider>
            </UIProvider>
            <EnvScript env={env} />
            <ScrollRestoration />
            <Scripts />
            <ThirdPartyScripts />
          </body>
        </html>
      </UIAppProvider>
    </ShellStoreProvider>
  );
}

export default function App() {
  return <Outlet />;
}

export const ErrorBoundary = () => {
  const env = getPublicEnvVars();
  const { PUBLIC_APP_VERSION, PUBLIC_APP_ENV } = env;
  const error = useRouteError();
  const isRouteResponseError = isRouteErrorResponse(error);
  const status = isRouteResponseError ? error.status : 500;
  const isServer = typeof window === 'undefined';

  if (PUBLIC_APP_ENV !== 'development' && isServer && !isRouteResponseError) {
    import('rollbar').then(mod => {
      const { PRIVATE_ROLLBAR_ACCESS_TOKEN } = getPrivateEnvVars();
      const Rollbar = mod.default;
      const rollbar = new Rollbar({
        accessToken: PRIVATE_ROLLBAR_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: PUBLIC_APP_ENV,
        payload: {
          environment: PUBLIC_APP_ENV,
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: PUBLIC_APP_VERSION,
              guess_uncaught_frames: true
            }
          }
        }
      });

      rollbar.error(error as Error);
    });
  }

  return getErrorComponent(status);
};

const EnvScript = ({ env }: { env: PublicEnvVars }) => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `self.ENV = ${JSON.stringify(env)}`
      }}
    />
  );
};

const ThirdPartyScripts = () => {
  return (
    <>
      <ZarazScript />
    </>
  );
};

const ZarazScript = () => {
  const env = getPublicEnvVars();
  const handleZarazLoad = useShellStore(s => s.setZarazFetched);

  useMounted(() => {
    // Ensure zaraz loaded event is always called in development.
    if (env.PUBLIC_APP_ENV === 'development') {
      handleZarazLoad();
    }
  });

  return env.PUBLIC_APP_ENV === 'development' ? null : (
    <Script
      src="/cdn-cgi/zaraz/i.js"
      strategy="lazyOnload"
      onLoad={handleZarazLoad}
    />
  );
};

const getErrorComponent = (status: number) => <ErrorScreen status={status} />;
