import { HTMLProps, SyntheticEvent, useEffect } from 'react';

/**
 * Extracted from https://github.com/vercel/next.js/blob/canary/packages/next/client/script.tsx
 */
const ScriptCache = new Map();
const LoadCache = new Set();

export interface ScriptProps extends HTMLProps<HTMLScriptElement> {
  strategy?:
    | 'afterInteractive'
    | 'lazyOnload'
    | 'normal'
    | 'worker'
    | 'userInteraction';
  id?: string;
  onReady?: () => void;
}

/**
 * @deprecated Use `ScriptProps` instead.
 */
export type Props = ScriptProps;

const ignoreProps = new Set([
  'onLoad',
  'onError',
  'dangerouslySetInnerHTML',
  'strategy'
]);

const DOMAttributeNames: Record<string, string> = {
  acceptCharset: 'accept-charset',
  className: 'class',
  htmlFor: 'for',
  httpEquiv: 'http-equiv',
  noModule: 'noModule'
};

const loadScript = (props: ScriptProps): void => {
  const {
    src,
    id,
    onLoad = () => {},
    onReady = null,
    dangerouslySetInnerHTML,
    strategy = 'userInteraction',
    onError
  } = props;

  const cacheKey = id ?? src;

  // Script has already loaded
  if (cacheKey && LoadCache.has(cacheKey)) {
    return;
  }

  // Contents of this script are already loading/loaded
  if (ScriptCache.has(src)) {
    LoadCache.add(cacheKey);

    if (typeof onLoad === 'function') {
      ScriptCache.get(src).then(onLoad, onError);
    }

    return;
  }

  /** Execute after the script first loaded */
  const afterLoad = () => {
    // Run onReady for the first time after load event
    if (onReady) {
      onReady();
    }
    // add cacheKey to LoadCache when load successfully
    LoadCache.add(cacheKey);
  };

  const el = document.createElement('script');

  const loadPromise = new Promise<void>((resolve, reject) => {
    el.addEventListener('load', e => {
      resolve();

      if (typeof onLoad === 'function') {
        onLoad(e as unknown as SyntheticEvent<HTMLScriptElement, Event>);
      }
      afterLoad();
    });
    el.addEventListener('error', e => {
      reject(e);
    });
  }).catch(error => {
    if (typeof onError === 'function') {
      onError(error);
    }
  });

  if (dangerouslySetInnerHTML) {
    el.innerHTML = (dangerouslySetInnerHTML as unknown as string) || '';

    afterLoad();
  } else if (src) {
    el.src = src;
    // do not add cacheKey into LoadCache for remote script here
    // cacheKey will be added to LoadCache when it is actually loaded (see loadPromise above)

    ScriptCache.set(src, loadPromise);
  }

  for (const [k, value] of Object.entries(props)) {
    if (value === undefined || ignoreProps.has(k)) {
      continue;
    }

    const attr = DOMAttributeNames[k] || k?.toLowerCase();
    el.setAttribute(attr, value);
  }

  if (strategy === 'worker') {
    el.setAttribute('type', 'text/partytown');
  }

  document.body.append(el);
};

const loadLazyScript = (props: ScriptProps) => {
  if (document.readyState === 'complete') {
    requestIdleCallback(() => loadScript(props));
  } else {
    window.addEventListener('load', () => {
      requestIdleCallback(() => loadScript(props));
    });
  }
};

const loadScriptOnUserInteraction = (props: ScriptProps) => {
  execAfterUserInteraction(() => {
    loadScript(props);
  }, 10_000);
};

export const Script = (props: ScriptProps) => {
  const { id, src = '', strategy = 'lazyOnload', ...restProps } = props;

  useEffect(() => {
    switch (strategy) {
      case 'afterInteractive': {
        loadScript(props);

        break;
      }

      case 'lazyOnload': {
        loadLazyScript(props);

        break;
      }

      case 'userInteraction': {
        loadScriptOnUserInteraction(props);

        break;
      }
      // No default
    }
  }, [strategy, props]);

  if (strategy === 'normal' || strategy === 'worker') {
    return (
      <script
        id={id}
        {...restProps}
        {...(src ? { src } : {})}
        {...(strategy === 'worker' ? { type: 'text/partytown-x' } : {})}
      />
    );
  }

  return null;
};

const isBrowser = typeof window !== 'undefined';

export const requestIdleCallback =
  (isBrowser &&
    window.requestIdleCallback &&
    window.requestIdleCallback.bind(window)) ||
  ((callback: IdleRequestCallback): number => {
    const start = Date.now();
    return setTimeout(() => {
      callback({
        didTimeout: false,
        timeRemaining: () => Math.max(0, 50 - (Date.now() - start))
      });
    }, 1) as unknown as number;
  });

export const execAfterUserInteraction = (cb: () => void, timeout?: number) => {
  let hasInteracted = false;
  let timeoutId: NodeJS.Timeout;

  const clearTimeoutId = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined!;
    }
  };

  if (hasInteracted) {
    cb();
    clearTimeoutId();
    return;
  }

  if (timeout) {
    timeoutId = setTimeout(() => {
      if (!hasInteracted) {
        cb();
        clearTimeoutId();
      }
    }, timeout);
  }

  const listener = () => {
    if (hasInteracted) {
      return;
    }

    hasInteracted = true;

    cb();
    clearTimeoutId();

    for (const eventName of USER_ACTIVITY_EVENTS) {
      document.removeEventListener(eventName, listener);
    }
  };

  for (const eventName of USER_ACTIVITY_EVENTS) {
    document.addEventListener(eventName, listener, true);
  }
};

const USER_ACTIVITY_EVENTS = [
  'mousedown',
  'mousemove',
  'keydown',
  'scroll',
  'touchstart'
];
